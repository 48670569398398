<script>
import { Card, Button, Popconfirm } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import CardLabelModal from '../CardLabelModal'
import SvgIcon from '../SvgIcon'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    icons: {
      type: Array,
      default: () => []
    },
    styles: {
      type: Object,
      default: () => {}
    },
    pubBool: {
      type: Boolean,
      default: true
    },
    isAddBtn: {
      type: Boolean,
      default: true
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    carTitle: {
      type: String,
      default: '标签'
    },
    labelTypeCode: {
      type: String,
      default: ''
    }
  },
  methods: {
    onButtonDown() {
      let that = this
      apiTool.showDrawer({
        title: this.carTitle,
        width: '720px',
        view: CardLabelModal,
        viewProps: {
          data: this.data,
          showIcon: this.showIcon,
          labelTypeCode: this.labelTypeCode
        },
        success: data => {
          that.$emit('success', data)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    renderButton() {
      return (
        <template slot="extra">
          <Button
            ghost={true}
            type="primary"
            style={{ width: '80px', borderRadius: '5px' }}
            onClick={this.onButtonDown}
          >
            <a-icon type="plus" style={{ fontSize: '15px' }} />
            添加
          </Button>
        </template>
      )
    },
    onEdit(item, index) {
      let that = this
      apiTool.showDrawer({
        title: this.carTitle,
        width: '720px',
        view: CardLabelModal,
        viewProps: {
          form: item,
          data: this.data,
          showIcon: this.showIcon,
          labelTypeCode: this.labelTypeCode
        },
        success: data => {
          that.$emit('edit', data, index)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    onChose(item, index) {
      this.$emit('chose', item, index)
    },
    renderIconsItem(item, i) {
      return (
        <div
          onClick={() => this.onChose(item, i)}
          class="icons-button"
          style={{
            borderColor: item.light == 1 ? 'rgba(45, 140, 240, 1)' : ''
          }}
        >
          {item.iconUrl && <img src={item.iconUrl} class="svg-icon" />}
          <span>{item.name}</span>
          {(((item.publicLabel == 2 && !this.pubBool) || this.pubBool) && item.labelName != '普通用户') && (
            <a-icon type="edit" onClick={() => this.onEdit(item, i)} />
          )}
          {(((item.publicLabel == 2 && !this.pubBool) || this.pubBool) && item.labelName != '普通用户' ) && (
            <Popconfirm
              title="确认是否删除"
              ok-text="确认"
              cancel-text="取消"
              onConfirm={() => this.$emit('delete', item, i)}
            >
              <a-icon type="delete" />
            </Popconfirm>
          )}
        </div>
      )
    },
    renderIcons() {
      return (
        <div class="icons-label-group">
          {this.icons.map((e, i) => {
            return this.renderIconsItem(e, i)
          })}
        </div>
      )
    }
  },
  render() {
    return (
      <Card title={this.title} size={'small'} style={this.styles}>
        {this.isAddBtn && this.renderButton()}
        {this.renderIcons()}
      </Card>
    )
  }
}
</script>
<style lang="less" scoped>
.icons-button {
  display: flex;
  align-items: center;
  justify-content: center !important;
  margin-top: 10px;
  margin-right: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 1px solid transparent;
  border-color: #d9d9d9;
  border-radius: 5px;
  height: 32px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    border-color: #1890ff;
  }
  /deep/span {
    margin-left: 10px;
    margin-right: 5px;
  }
  i {
    margin-right: 10px;
  }
}
.icons-label-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
}
.svg-icon {
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
}

/deep/.ant-card-head,
/deep/.ant-card-body {
  padding-left: 24px;
}

/deep/.ant-card-head-title {
  font-size: 16px;
}
</style>
